import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/footer";
import Pastevent from "../components/pastevent";
import { Grid, Hidden } from "@material-ui/core";
import WrapTypo from "../components/WrapTypo";
import { Button } from "@material-ui/core";
import { navigate } from "gatsby";
import ImageContent from "../components/imageContent";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const fetchProductData = async () => {
  const fetchProduct = await fetch(
    "https://hayafuji.microcms.io/api/v1/works?limit=40&fields=id,topImage,title",
    { headers: { "X-API-KEY": "4c838704-f501-404a-b96b-23aabd25962f" } }
  );
  try {
    return await fetchProduct.json();
  } catch (e) {}
};

export default function Home(props) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [artList, setArtList] = useState([]);
  const productArray = [];
  const [choiceArt, setChoiceArt] = useState(-1);
  useEffect(() => {
    fetchProductData().then(data => {
      data &&
        data.contents &&
        data.contents.map(c => {
          productArray.push(c);
        });
      setArtList([...productArray]);
    });
  }, [setArtList]);

  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 2000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom"
    });
  }

  const data = useStaticQuery(graphql`
    query {
      pc_top: file(relativePath: { eq: "topImage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_top: file(relativePath: { eq: "mobileImg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#fff">
        <Header isBlack />
        <Box width="100%">
          <Box
            width="95%"
            maxWidth="600px"
            m={"auto"}
            textAlign="center"
            mt={30}
          >
            <WrapTypo
              color="#000"
              lh="250%"
              fs="24px"
              ls="12px"
              xsfs="18px"
              data-aos="fade-in"
            >
              一度書いた設計図は、
              <br />
              二度と使わない。
            </WrapTypo>
            <WrapTypo
              color="#000"
              ls="2px"
              lh="300%"
              fs="16px"
              mt={"40px"}
              data-aos="fade-in"
              xsfs="12px"
            >
              設計図を使いまわせば早く簡単に家を作れます。
              <br />
              ですが、早藤工務店では絶対にしません。
              <br />
              十人いれば十通りの家が必要だからです。
              <br />
              お客様の数だけ設計図を作りました。
              <br />
              積み上げられた設計図の高さが実証の証です。
              <br />
              あなたを出迎える時も、もちろんゼロから。
              <br />
              一緒に夢を描きましょう。
              <br />
              <br />
              早藤工務店　代表取締役　早藤　亮
            </WrapTypo>
          </Box>

          <Box
            margin="192px auto 0"
            data-aos="fade-up"
            style={{
              WebkitWritingMode: "vertical-rl",
              msWritingMode: "tb-rl",
              writingMode: "vertical-rl"
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <WrapTypo>・</WrapTypo>
              </Grid>
              <Grid item>
                <WrapTypo mt="50px">施工事例一覧</WrapTypo>
              </Grid>
            </Grid>
          </Box>
          <ImageContent artList={artList} />
        </Box>
        <Footer />
      </Box>
    </>
  );
}
