import React from "react";
import Box from "@material-ui/core/Box";
import { Link } from "gatsby";
import { Grid } from "@material-ui/core";
import WrapTypo from "../components/WrapTypo";
import { Button } from "@material-ui/core";
import { navigate } from "gatsby";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import MuiButton from "@material-ui/core/Button";

const ButtonA = styled(MuiButton)(spacing);


function ImageContent({ artList }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <Box width="100%" margin="70px auto 0">
        <Grid container spacing={isXs ? 1 : 0}>
          {artList.map((c, i) =>
            i <= 2 ? (
              <ImgContent
                title={c.title}
                src={c.topImage.url}
                id={c.id}
                xsflag={true}
                n={i}
              />
            ) : (
              <ImgContent
                title={c.title}
                src={c.topImage.url}
                id={c.id}
                xsflag={false}
                n={i}
              />
            )
          )}
        </Grid>
      </Box>
    </>
  );
}

const ImgContent = ({ title, src, id, xsflag, n }) => {
  return (
    <>
      <Grid item xs={xsflag ? 12 : 6} sm={4}>
        <Box data-aos="fade-up" data-aos-duration="3000">
          <ButtonA
            style={{
              margin: 0,
              borderRadius: "0px",
              width: "100%"
            }}
            onClick={() => {
              if (typeof window !== `undefined`)
                window.location.href = "/building?room=" + id + "#top";
            }}
            p={0}
            pb={[3, 3, 10]}
          >
            <Grid container justify="center">
              <Grid
                item
                style={{
                  width: "100%",
                  height: "100%"
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    paddingBottom: "61.8%",
                    height: "100%",
                    position: "relative"
                  }}
                >
                  <img
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      left: "0"
                    }}
                    src={src}
                  />
                </Box>
              </Grid>
              <Grid item>
                <WrapTypo mb="46px" xsfs="12px" ls="10px" mt="20px">
                  {title}
                </WrapTypo>
              </Grid>
            </Grid>
          </ButtonA>
        </Box>
      </Grid>
    </>
  );
};

export default ImageContent;
